import cn from 'classnames';
import React from 'react';

import Heading from '../Heading';
import Section from '../Section';

import AppGraphic from '@components/AppGraphic';
import CtaForm from '@components/common/CtaForm';

const Hero = ({
  title = 'Empowering data teams',
  description = `PopSQL is the SQL editor for streamlined teamwork and insightful data
collaboration.`,
  ctaText = 'Get started',
}: {
  ctaText?: string;
  description: string;
  title: string;
}) => {
  return (
    <Section
      spacing="mt-8 sm:mt-20 px-4 gap-4"
      customStyles={{
        background: `url(${process.env.NEXT_PUBLIC_ASSET_HOST}/static/homepage/home_hero_bg.svg)`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
    >
      <Heading
        className={cn(
          'text-center',
          'motion-safe:opacity-0 motion-safe:animate-fadeInFromTop motion-safe:animation-delay-200',
        )}
      >
        {title}
      </Heading>

      <p
        className={cn(
          'max-w-3xl',
          'font-inter text-center font-medium sm:text-xl text-gray-600',
          'motion-safe:opacity-0 motion-safe:animate-fadeInFromTop motion-safe:animation-delay-300',
        )}
      >
        {description}
      </p>

      <CtaForm ctaText={ctaText} />

      {/* <Button variant="ghost" hasArrow>
        {secondaryCtaText}
      </Button> */}

      <AppGraphic />
    </Section>
  );
};
export default Hero;
