import { Alignment, Fit, Layout, useRive } from '@rive-app/react-canvas';
import Image from 'next/image';
import React from 'react';
import { isMobile } from 'react-device-detect';

const HeroAnimation = () => {
  const { RiveComponent: RiveComponentPlayback } = useRive({
    src: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/rive/homepage.riv`,
    animations: 'Timeline',
    layout: new Layout({
      fit: Fit.FitWidth,
      alignment: Alignment.TopCenter,
    }),
    autoplay: true,
  });

  return (
    <RiveComponentPlayback
      aria-label="PopSQL screen animation"
      className="w-full md:max-w-[900px] lg:max-w-[1200px] lg:h-[575px] aspect-video mx-auto rounded-[16px] my-8"
    />
  );
};

const AppGraphic = () => {
  return (
    <>
      {isMobile ? (
        <Image
          className="motion-safe:opacity-0 motion-safe:animate-fadeInFromBottom motion-safe:animation-delay-100"
          width={1080}
          height={504}
          alt="PopSQL screen"
          src={`${process.env.NEXT_PUBLIC_ASSET_HOST}/static/homepage/mobile-screen.jpg`}
        />
      ) : (
        <HeroAnimation />
      )}
    </>
  );
};
export default AppGraphic;
