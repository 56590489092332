import cn from 'classnames';
import React from 'react';

import Heading from '../Heading';
import Section from '../Section';

const IntroSection = ({
  title = 'Drive better outcomes, faster than ever',
  description = `Bridge the gap between data analysts, data engineers, and other teams.`,
}) => {
  return (
    <Section className="pt-8 sm:pt-16">
      <Heading
        Tag="h2"
        size="lg"
        className={cn('sm:max-w-xl mx-auto', 'text-black text-center')}
      >
        {title}
      </Heading>

      <p
        className={cn(
          'text-base sm:text-xl sm:max-w-2xl',
          'font-inter font-medium text-gray-600 dark:text-bliss text-center',
        )}
      >
        {description}
      </p>
    </Section>
  );
};
export default IntroSection;
