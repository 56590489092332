import cn from 'classnames';
import React from 'react';
import SVG from 'react-inlinesvg';

import Section from '../Section';
import Slider from '../Slider';

import { ContentfulImage } from '@utils/propTypes';

const CUSTOMER_LOGOS_DEFAULT = [
  {
    title: 'optimizely logo',
    file: {
      url: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/homepage/customers/optimizely.svg`,
    },
  },
  {
    title: 'vercel logo',
    file: {
      url: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/homepage/customers/vercel.svg`,
    },
  },
  {
    title: 'redfin logo',
    file: {
      url: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/homepage/customers/redfin.svg`,
    },
  },
  {
    title: 'cavenders logo',
    file: {
      url: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/homepage/customers/cavenders.svg`,
    },
  },
  {
    title: 'udacity logo',
    file: {
      url: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/homepage/customers/udacity.svg`,
    },
  },
  {
    title: 'jobandtalent logo',
    file: {
      url: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/homepage/customers/jobandtalent.svg`,
    },
  },
  {
    title: 'branch logo',
    file: {
      url: `${process.env.NEXT_PUBLIC_ASSET_HOST}/static/homepage/customers/branch.svg`,
    },
  },
];

const CustomerLogos = ({
  heading = `PopSQL is trusted by 2000+ of the world's top data teams`,
  logos = CUSTOMER_LOGOS_DEFAULT,
  spacing = `my-8 sm:my-16 gap-4`,
}: {
  heading?: string;
  logos?: ContentfulImage[];
  spacing?: string;
}) => {
  return (
    <Section
      spacing={cn(
        `${spacing} w-full text-gray-400 dark:text-bliss`,
        'motion-safe:opacity-0 motion-safe:animate-fadeInFromBottom motion-safe:animation-delay-200',
      )}
    >
      <p
        className={
          'text-xs sm:text-sm px-4 text-gray-400 dark:text-bliss mb-2 text-center font-inter font-medium'
        }
      >
        {heading}
      </p>
      <Slider duration={15}>
        {logos.map((item, i) => (
          <figure
            className="inline-block"
            aria-label={`${item.title} logo`}
            key={i}
          >
            <SVG
              height="84"
              width="165"
              className="fill-current"
              key={i}
              src={item.file.url}
            />
          </figure>
        ))}
      </Slider>
    </Section>
  );
};

export default CustomerLogos;
