import cn from 'classnames';
import Image from 'next/image';
import React from 'react';

import Button from '@components/Button';
import Heading from '@components/Heading';
import Section from '@components/Section';
import { loaderProp } from '@utils/random';

const EnterpriseGradeSection = ({ data }) => {
  return (
    <Section className="pt-8 sm:pt-16 lg:pt-32">
      <div>
        <Heading
          Tag="h2"
          size="lg"
          className="mx-auto text-black text-center mb-4"
        >
          {data.heading}
        </Heading>

        <p
          className={cn(
            'text-base sm:text-xl sm:max-w-2xl',
            'font-inter font-medium text-gray-600 dark:text-bliss text-center',
          )}
        >
          {data.subHeading}
        </p>
      </div>

      <div className="mt-8 md:mt-24 grid md:grid-cols-3 gap-8 max-w-[500px] md:max-w-full">
        <div className="border md:shadow-features border-gray-200 rounded-[34px] overflow-hidden">
          <Image
            src={data.card1Image.file.url}
            alt={`${data.card1Image.description} illustration`}
            height="468"
            width="567"
            loader={loaderProp}
          />
          <div className="px-7 lg:px-9 pb-8 lg:pb-8 pt-4">
            <p className="text-[#22262A] font-semibold font-inter text-xl">
              {data.card1Heading}
            </p>

            <p className="min-h-[130px] text-gray-600 text-base font-inter font-medium mt-4 max-w-md mb-4">
              {data.card1SubHeading}
            </p>

            <Button
              className="inline-flex"
              hasArrow
              variant="text"
              href={data.card1CtaUrl}
            >
              {data.card1CtaText}
            </Button>
          </div>
        </div>
        <div className="border md:shadow-features border-gray-200 rounded-[34px] overflow-hidden">
          <Image
            src={data.card2Image.file.url}
            alt={`${data.card2Image.description} illustration`}
            height="468"
            width="567"
            loader={loaderProp}
          />
          <div className="px-7 lg:px-9 pb-8 lg:pb-8 pt-4">
            <p className="text-[#22262A] font-semibold font-inter text-xl">
              {data.card2Heading}
            </p>

            <p className="min-h-[130px] text-gray-600 text-base font-inter font-medium mt-4 max-w-md mb-4">
              {data.card2SubHeading}
            </p>

            <Button
              className="inline-flex"
              hasArrow
              variant="text"
              href={data.card2CtaUrl}
            >
              {data.card2CtaText}
            </Button>
          </div>
        </div>
        <div className="border md:shadow-features border-gray-200 rounded-[34px] overflow-hidden">
          <Image
            src={data.card3Image.file.url}
            alt={`${data.card3Image.description} illustration`}
            height="468"
            width="567"
            loader={loaderProp}
          />
          <div className="px-7 lg:px-9 pb-8 lg:pb-8 pt-4">
            <p className="text-[#22262A] font-semibold font-inter text-xl">
              {data.card2Heading}
            </p>

            <p className="min-h-[130px] text-gray-600 text-base font-inter font-medium mt-4 max-w-md mb-4">
              {data.card3SubHeading}
            </p>

            <Button
              className="inline-flex"
              hasArrow
              variant="text"
              href={data.card3CtaUrl}
            >
              {data.card3CtaText}
            </Button>
          </div>
        </div>
      </div>
    </Section>
  );
};

export default EnterpriseGradeSection;
