export const parseArrayObj = (arr) => {
  const newArr = [];
  arr.forEach((a) => {
    newArr.push(a.fields);
  });

  return newArr;
};

export const parseContentfulData = (objData) => {
  const keys = Object.keys(objData);
  const obj = {};

  keys.forEach((key) => {
    obj[key] = objData[key];

    if (Array.isArray(objData[key])) {
      obj[key] = parseArrayObj(objData[key]);
    } else if (objData[key].fields) {
      obj[key] = parseContentfulData(objData[key].fields);
    }
  });

  return obj;
};
